import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import { environment } from "../../../../../environments/environment";

@Component({
  selector: "app-edit-tenants",
  templateUrl: "./edit-tenants.component.html",
  styleUrls: ["./edit-tenants.component.scss"]
})
export class EditTenantsComponent {
  tenantForm: FormGroup;
  isCreating: boolean;
  countries;
  industries;
  tenants: any;
  editingIndex: any;
  errorMessage = false;
  platformId = environment.platformId;

  constructor(
    public dialogRef: MatDialogRef<EditTenantsComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      tenant: any;
      create: boolean;
      industries: any;
      countries: any;
    },
    private fb: FormBuilder
  ) {
    this.isCreating = data.create;
    this.industries = data.industries;
    this.countries = data.countries;
    this;
    this.initTenantForm();
  }
  ngOnInit(): void {
    this.tenantForm.patchValue(this.data?.tenant);
  }

  save() {
    if (this.tenantForm.valid) {
      this.dialogRef.close(this.tenantForm.value);
    } else {
      this.errorMessage = true;
    }
  }
  close() {
    this.dialogRef.close();
  }

  initTenantForm() {
    this.tenantForm = this.fb.group({
      id: new FormControl(),
      name: new FormControl("", Validators.required),
      industry: new FormControl(null, Validators.required),
      country: new FormControl(
        "",
        this.platformId == "mx" ? Validators.required : {}
      ),
      contactUrl: new FormControl(""),
      contactName: new FormControl(""),
      contactPhone: new FormControl(""),
      contactEmail: new FormControl("", Validators.email),
      tenant: new FormControl(true),
      ...(!this.isCreating ? { createdDate: new FormControl("") } : {})
    });
  }

  compareIndustries(ind1: any, ind2: any): boolean {
    return ind1 && ind2 ? ind1.id === ind2.id : ind1 === ind2;
  }
}
