import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { env } from "process";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class PropertyService {
  constructor(private http: HttpClient) {}

  getPropertyId(id) {
    return this.http.get(`${environment.apiURL}/buildings/${id}`).toPromise();
  }

  getUnitMixByPropertyId(id) {
    return this.http
      .get(`${environment.apiURL}/units-mix/building/${id}`)
      .toPromise();
  }

  getFeatureDetailsByPropertyId(id, lang) {
    return this.http
      .get(
        `${environment.apiURL}/building/features/detail/building/${id}/lang/${lang}`
      )
      .toPromise();
  }

  fetchTenantsAutocompleteResults(query: string): Observable<any[]> {
    let encodedQuery = encodeURIComponent(query);
    const apiUrl = `${environment.apiURL}/building-rent-rolls/corporations/tenants?query=${encodedQuery}`;
    return this.http.get<any[]>(apiUrl);
  }

  getFeatureIdsPropertyId(id) {
    return this.http
      .get(`${environment.apiURL}/building/features/building/${id}`)
      .toPromise();
  }

  getPhotosById(id) {
    return this.http
      .get(`${environment.apiURL}/buildingPhotosByBuilding?buildingId=${id}`)
      .toPromise();
  }

  downloadReport(id) {
    return this.http
      .post(`${environment.apiURL}/buildingPrints/word/building`, id)
      .toPromise();
  }

  getProperties(classId, regionId, buildingType, subMarketId = null) {
    let paramsBr = {
      classTypeIds: classId,
      regionId: regionId,
      random: true,
      buildingTypes: [buildingType],
      status: 1,
      propertyStatusIds: [1]
    };

    let paramsMx = {
      subMarketId: subMarketId,
      random: true,
      buildingTypes: [buildingType],
      status: 1,
      propertyStatusIds: [1001]
    };
    return this.http
      .post(
        `${environment.apiURL}/buildings/searchBuildings?page=1&per_page=20`,
        environment.platformId == "mx" ? paramsMx : paramsBr
      )
      .toPromise();
  }

  saveTenantsData(actions) {
    return this.http
      .post(`${environment.apiURL}/building-tenant/tenants`, actions)
      .toPromise();
  }

  saveBuildingResearcher(buildingId, researcherId) {
    let payload = {
      buildingId: buildingId,
      researcherId: researcherId
    };
    return this.http
      .post(`${environment.apiURL}/building-tenant/research/update`, payload)
      .toPromise();
  }

  getExchangeRate(date) {
    let payload = {
      date: date
    };
    return this.http
      .post(
        `${environment.apiURL}/building-tenant/research/exchange-rate`,
        payload
      )
      .toPromise();
  }

  saveMarketResearcher(marketId, researcherId) {
    let payload = {
      marketId: marketId,
      researcherId: researcherId
    };
    return this.http
      .post(
        `${environment.apiURL}/building-tenant/market/research/update`,
        payload
      )
      .toPromise();
  }

  saveMarketLeadResearcher(marketId, researcherId) {
    let payload = {
      marketId: marketId,
      researcherId: researcherId
    };
    return this.http
      .post(
        `${environment.apiURL}/building-tenant/market/lead-research/update`,
        payload
      )
      .toPromise();
  }

  rejectAndAddComments(configId, comments) {
    let payload = {
      configId: configId,
      comments: comments
    };
    return this.http
      .post(
        `${environment.apiURL}/building-tenant/config/${configId}/reject`,
        payload
      )
      .toPromise();
  }

  rejectAndAddMarketComments(configId, comments) {
    let payload = {
      marketConfigId: configId,
      comments: comments
    };
    return this.http
      .post(
        `${environment.apiURL}/building-tenant/market-config/${configId}/reject`,
        payload
      )
      .toPromise();
  }

  saveMarketComments(configId, comments) {
    let payload = {
      marketConfigId: configId,
      comments: comments
    };
    return this.http
      .post(
        `${environment.apiURL}/building-tenant/market-config/${configId}/comments`,
        payload
      )
      .toPromise();
  }

  saveComments(configId, comments) {
    let payload = {
      configId: configId,
      comments: comments
    };
    return this.http
      .post(
        `${environment.apiURL}/building-tenant/config/${configId}/comments`,
        payload
      )
      .toPromise();
  }

  saveBuildingFlags(configId, flags) {
    let payload = {
      configId: configId,
      flags: flags
    };
    return this.http
      .post(
        `${environment.apiURL}/building-tenant/config/${configId}/flags`,
        payload
      )
      .toPromise();
  }

  getBuildingTenantCommentsByConfigId(configId) {
    return this.http
      .get(`${environment.apiURL}/building-tenant/config/${configId}/comments`)
      .toPromise();
  }

  submitTenantChangesForApproval(configId) {
    return this.http
      .post(
        `${environment.apiURL}/building-tenant/config/${configId}/submit`,
        null
      )
      .toPromise();
  }

  submitMarketForApproval(configId) {
    return this.http
      .post(
        `${environment.apiURL}/building-tenant/market-config/${configId}/submit`,
        null
      )
      .toPromise();
  }

  approveMarket(configId) {
    return this.http
      .post(
        `${environment.apiURL}/building-tenant/market-config/${configId}/approve`,
        null
      )
      .toPromise();
  }

  approveTenantChanges(configId) {
    return this.http
      .post(
        `${environment.apiURL}/building-tenant/config/${configId}/approve`,
        null
      )
      .toPromise();
  }

  markPropertyAsRemovedFromStats(configId) {
    return this.http
      .post(
        `${environment.apiURL}/building-tenant/config/${configId}/remove-from-stats`,
        null
      )
      .toPromise();
  }

  downloadAllStatsCsvWithChanges(): Promise<any> {
    return this.http
      .post(
        `${environment.apiURL}/building-tenant/research/generate-stats-file`,
        null,
        {
          responseType: "text"
        }
      )
      .toPromise();
  }

  downloadStatsCsvWithChanges(buildingId: number): Promise<any> {
    return this.http
      .post(
        `${environment.apiURL}/building-tenant/building/${buildingId}/research/generate-stats-file-test`,
        null,
        {
          responseType: "text"
        }
      )
      .toPromise();
  }

  downloadStatsCsvByMarket(marketId: number): Promise<any> {
    return this.http
      .post(
        `${environment.apiURL}/building-tenant/market/${marketId}/research/generate-stats-file`,
        null,
        {
          responseType: "text"
        }
      )
      .toPromise();
  }

  markTenantAsReviewed(tenantId, reviewed) {
    return this.http
      .post(
        `${environment.apiURL}/building-tenant/tenant/${tenantId}/reviewed`,
        reviewed
      )
      .toPromise();
  }

  changeSiteVisitStatus(buildingId, siteVisit) {
    return this.http
      .post(
        `${environment.apiURL}/building-tenant/building/${buildingId}/visited`,
        siteVisit
      )
      .toPromise();
  }

  restartTenantsConfig(configId) {
    return this.http
      .post(
        `${environment.apiURL}/building-tenant/config/${configId}/restart-config`,
        null
      )
      .toPromise();
  }

  sendNotification(mail) {
    return this.http.put(`${environment.apiURL}/inboxs`, mail).toPromise();
  }

  getListingsSpot(id, comparable = false) {
    return this.http
      .get(
        `${environment.apiURL}/buildinglisting/find/spot/building/${
          comparable ? id + "/comparable" : id
        }`
      )
      .toPromise();
  }

  getBuildingRentRollsVacant(id) {
    return this.http
      .get(`${environment.apiURL}/building-rent-rolls/vacant?building=${id}`)
      .toPromise();
  }

  getBuildingRentRolls(id) {
    return this.http
      .get(`${environment.apiURL}/building-rent-rolls?building=${id}`)
      .toPromise();
  }

  getBuildingOccupanciesRegular(id) {
    return this.http
      .get(`${environment.apiURL}/building-occupancies?building=${id}`)
      .toPromise();
  }

  getBuildingOccupancies(id) {
    return this.http
      .get(
        `${environment.apiURL}/building-occupancies/market-rent?building=${id}`
      )
      .toPromise();
  }

  getIndustryPercentages(id) {
    return this.http
      .get(
        `${environment.apiURL}/building-rent-rolls/industry-percentages?building=${id}`
      )
      .toPromise();
  }

  getLastQuarterTenantsInfo(id) {
    return this.http
      .get(`${environment.apiURL}/building-tenant?building=${id}`)
      .toPromise();
  }

  getFloorExpansionData(configId: number, buildingId: number): Promise<any[]> {
    return this.http
      .get<any[]>(
        `${environment.apiURL}/building-tenant/config/${configId}/building/${buildingId}/floor-expansion`
      )
      .toPromise();
  }

  saveFloorExpansionData(
    configId: number,
    buildingId: number,
    data: any[]
  ): Promise<any> {
    return this.http
      .post<any>(
        `${environment.apiURL}/building-tenant/config/${configId}/building/${buildingId}/floor-expansion`,
        data
      )
      .toPromise();
  }

  discardFloorExpansionData(
    configId: number,
    buildingId: number
  ): Promise<any> {
    return this.http
      .delete<any>(
        `${environment.apiURL}/building-tenant/config/${configId}/building/${buildingId}/discard-floor-expansion`
      )
      .toPromise();
  }

  discardSuiteAreasData(buildingId: number): Promise<any> {
    return this.http
      .delete<any>(
        `${environment.apiURL}/building-tenant/building/${buildingId}/discard-suite-areas`
      )
      .toPromise();
  }

  getAskingRentData(buildingId: number): Promise<any> {
    return this.http
      .get<any>(
        `${environment.apiURL}/building-tenant/building/${buildingId}/asking-rent`
      )
      .toPromise();
  }

  getTenantsData(buildingId: number): Promise<any> {
    return this.http
      .get<any>(
        `${environment.apiURL}/building-tenant/building/${buildingId}/tenants`
      )
      .toPromise();
  }

  saveSuiteAreasData(buildingId: number, data: any): Promise<any> {
    return this.http
      .post<any>(
        `${environment.apiURL}/building-tenant/building/${buildingId}/suite-areas`,
        data
      )
      .toPromise();
  }

  saveAskingRentData(buildingId: number, data: any): Promise<any> {
    return this.http
      .post<any>(
        `${environment.apiURL}/building-tenant/building/${buildingId}/asking-rent`,
        data
      )
      .toPromise();
  }

  discardAskingRentData(buildingId: number): Promise<any> {
    return this.http
      .delete<any>(
        `${environment.apiURL}/building-tenant/building/${buildingId}/asking-rent/discard`
      )
      .toPromise();
  }

  getResearchDataByMarket(id) {
    return this.http
      .get(`${environment.apiURL}/building-tenant/reseach/market/${id}`)
      .toPromise();
  }

  getMarketResearchConfig() {
    return this.http
      .get(`${environment.apiURL}/building-tenant/reseach/markets`)
      .toPromise();
  }

  getResearchUsers() {
    return this.http
      .get(`${environment.apiURL}/users/research-role`)
      .toPromise();
  }

  getLeadResearchUsers() {
    return this.http
      .get(`${environment.apiURL}/users/research-lead-role`)
      .toPromise();
  }

  getMarketSegments() {
    return this.http
      .get(`${environment.apiURL}/building-rent-rolls/segments`)
      .toPromise();
  }

  getPointOfInterest(id) {
    return this.http
      .get(`${environment.apiURL}/point-of-interest/building/${id}`)
      .toPromise();
  }

  getListingsSpotVacancy(id) {
    return this.http
      .get(`${environment.apiURL}/buildinglisting/find/all-spot/building/${id}`)
      .toPromise();
  }

  allFundBuildingsByBuilding(id) {
    return this.http
      .get(`${environment.apiURL}/realEstateFundsBuilding/building/${id}`)
      .toPromise();
  }

  getInformationComplex(id) {
    return this.http
      .get(
        `${environment.apiURL}/buildings/industrial-complex/information?complexId=${id}`
      )
      .toPromise();
  }

  getBuildingOccupanciesMx(id) {
    return this.http
      .get(
        `${environment.apiURL}/building-occupancies/latest-quarter?buildingId=${id}`
      )
      .toPromise();
  }

  saveNotes(propertyId: number, notes: any[]): Promise<any> {
    let payload = {
      buildingId: propertyId,
      comments: notes
    };
    return this.http
      .post(
        `${environment.apiURL}/building-tenant/building/${propertyId}/notes`,
        payload
      )
      .toPromise();
  }

  getSpaceConditionByType() {
    return this.http
      .get(`${environment.apiURL}/lookUpTables/conditionType/O`)
      .toPromise();
  }
}
