import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { PropertyService } from "../../services/property.services";
import { ActivatedRoute, Router } from "@angular/router";
import { SiilaIndexReportComponent } from "../static-pages";
import { MatDialog } from "@angular/material/dialog";
import { environment } from "../../../../environments/environment";
import { ImageGalleryComponent } from "../image-gallery/image-gallery.component";
import { BreakpointObserver } from "@angular/cdk/layout";
import { SendNotificationComponent } from "../send-notification/send-notification.component";
import { generate, Observable, of, Subject, zip } from "rxjs";
import { AccountService, NewsService } from "../../../core";
import { NewsSearch } from "../../models/news-search.model";
import { NewsNavigateService } from "../../services/news.navigate.service";
import { SiilaTranslationService } from "../../../core/services/siila-translation.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexFill,
  ApexYAxis,
  ApexTooltip,
  ApexTitleSubtitle,
  ApexXAxis,
  ApexTheme
} from "ng-apexcharts";
import { apexObj, apexObj2 } from "../../utils/params";
import { DatePipe } from "@angular/common";
import { I18nService } from "../../services";
import { MetaService } from "@ngx-meta/core";
import { FeaturedListingService } from "../../../core/services/featured-listing.service";
import { DeviceRegistrationService } from "../../../core/services/device-registration.service";
import { ProductService } from "../../../core/services/products.service";
import { ProductItem } from "../../../core/models/products.model";
import { color } from "html2canvas/dist/types/css/types/color";
import { DomSanitizer } from "@angular/platform-browser";
import { CommonGenericService } from "../../services/common.service";
import * as e from "express";
import {
  PropertyFeaturesService,
  PropertyOptions
} from "../../services/property-features.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LocalNumberPipeSpot } from "../../pipes/number-pipe";
import { HttpClient } from "@angular/common/http";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap
} from "rxjs/operators";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis | ApexYAxis[];
  title: ApexTitleSubtitle;
  labels: string[];
  stroke: any;
  dataLabels: any;
  fill: ApexFill;
  tooltip: ApexTooltip;
  theme: ApexTheme;
  colors: string[];
  events: any;
  responsive: any;
};

const monthNamesMap = {
  Jan: "Ene",
  Feb: "Feb",
  Mar: "Mar",
  Apr: "Abr",
  May: "May",
  Jun: "Jun",
  Jul: "Jul",
  Aug: "Ago",
  Sep: "Sep",
  Oct: "Oct",
  Nov: "Nov",
  Dec: "Dic"
};

export type TenantAction = {
  id: number;
  buildingTenant: any;
  actionType: number;
  dateAction: Date;
  tenantNameEdit: string;
  marketSegment: any;
  newTenantArea: any;
  askingRentMXN: number;
  askingRentUSD: number;
};

export type BuildingFeatureDetails = {
  featureName: string;
  featureDisplayName: string;
  featureSelectedOptions: string[];
};

export type PropertyFeaturesIds = {
  id: number;
  buildingId: number;
  featureId: number;
  selectedOptions: number[];
  active: boolean;
};

export type UnitMix = {
  floorPlan: string;
  numberOfUnits: number;
  avgArea: number;
  cqAvgMonthlyRent: number;
  monthlyrentsm: number;
  cqAvgMonthlyRentFurnished: number;
  monthlyrentsmf: number;
  detailsName: string;
};

export type MarketSegment = {
  id: number;
  name: string;
};

@Component({
  selector: "app-market-stats-config",
  templateUrl: "./market-stats-config.component.html",
  styleUrls: ["./market-stats-config.component.scss"]
})
export class MarketStatsConfigComponent implements OnInit {
  dialogRef: any;
  id;
  isMobile: boolean = false;
  listingCards;
  property: any;
  photos: any[] = [];
  registry: any[] = [];
  plan: any[] = [];
  documents: any[] = [];
  buildings = [];
  propertiesComparable;
  platformId = environment.platformId;
  owners;
  developers;
  isAdmin = false;
  isResearchAdmin = false;
  articles: NewsSearch;
  browserLang;
  showDetail = false;
  vacantAreaList: any = [];
  countBomaArea = false;
  marketRentList: any = [];
  occupancies: any = [];
  researchData: any = [];
  marketSegments: Array<MarketSegment> = [];
  historicOccupancies: any = [];
  countBomaMarket = [];
  industryPercentagesList: any = [];
  public chartOptions: Partial<ChartOptions>;
  public chartOptionsOccupancy: Partial<ChartOptions>;
  public chartOptionsPie: Partial<ChartOptions>;
  tenantAreas: [] = [];
  showShortDesciption = true;
  hiddeMoreFeatures;
  pointOfInterest = [];
  vacancySpot: any;
  selectedMarketConfig: any;
  typeBar = 1;
  marketCardList = [];
  marketImgCard: string[];
  products: ProductItem[];
  currentCode: string;
  listFeatures: any = [];
  listManager: any = [];
  unitsMix: any = [];
  marketConfigs: any = [];
  researchUsers: any = [];
  leadResearchUsers: any = [];
  unitMixColVisibility: Map<string, boolean> = new Map();
  featuresOptions: BuildingFeatureDetails[] = [];
  validateMarketRent = [];
  showPhone = false;
  showEmail = false;
  rentRolls = [];
  currentFundsBuilding = [];
  neighborhoodDescription;
  customPropertyFeatures: PropertyOptions[] = [];
  keyFactsMultifamilyOpts: PropertyOptions[] = [];
  areasMeasurementsOpts: PropertyOptions[] = [];
  complexInformation = null;
  countPlannedDate = 0;
  buildingOccupancies: any;
  selectedTenant: any;
  currentAction: any;
  editMoveOutDate: Date;
  editAskingRentMXN: number;
  editAskingRentUSD: number;
  newTenantArea: number = 0;
  splitSuite: boolean = false;

  editTenantWithIndustry: any;
  selectedMarketSegment: MarketSegment;
  editTenantName: string = "";
  editIndustryName: string = "";
  autocompleteResults: Array<any> = [];
  isLoading: boolean = false;
  showAddTenants: boolean = false;
  addTenantMode: boolean = false;
  private searchSubject = new Subject<string>();
  showSpinner: boolean = false;

  @ViewChild("chartModal", { static: false }) chartModal!: ElementRef;

  get isBomaMarket() {
    return this.countBomaMarket && this.countBomaMarket.length > 0;
  }

  constructor(
    private propertyService: PropertyService,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private siilaTranslationService: SiilaTranslationService,
    private datePipe: DatePipe,
    private i18: I18nService,
    private deviceRegistration: DeviceRegistrationService,
    private accountService: AccountService,
    private modalService: NgbModal,
    private http: HttpClient,
    private snackBar: MatSnackBar
  ) {
    this.breakpointObserver
      .observe(["(max-width: 450px)"])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
    this.browserLang = this.siilaTranslationService.getCurrentLanguage();
    this.chartOptions = apexObj as ChartOptions;
    this.chartOptionsOccupancy = apexObj2 as ChartOptions;
    this.currentCode = environment.platformId;
  }

  async ngOnInit() {
    if (this.validateSesion()) {
      this.route.params.subscribe(params => {
        zip(
          this.propertyService.getMarketResearchConfig(),
          this.propertyService.getResearchUsers(),
          this.propertyService.getLeadResearchUsers()
        ).subscribe(async resp => {
          this.marketConfigs = resp[0];
          this.researchUsers = resp[1];
          this.leadResearchUsers = resp[2];
          this.selectedMarketConfig = this.marketConfigs[0];
          this.showDetail = true;
          if (!this.marketConfigs || this.marketConfigs.length == 0) {
            this.snackBar.open(
              this.i18.get("tenants-property.noMarketsAssigned"),
              "Close",
              {
                horizontalPosition: "center",
                verticalPosition: "top",
                panelClass: ["custom-snackbar"]
              }
            );
          }
        });
      });
    } else {
      this.router.navigateByUrl("/");
    }
  }

  formatDates(date: Date | string): string {
    if (!date) return "";

    var formatDate =
      this.i18.getCurrentLanguage() === "pt-br" ? "dd/MM/yyyy" : "MM/dd/yyyy";

    return this.datePipe.transform(date, formatDate) || "";
  }

  onAddTenant() {
    this.addTenantMode = true;
  }

  onBackToSearchTenant() {
    this.addTenantMode = false;
    this.showAddTenants = false;
    this.editTenantWithIndustry = null;
    this.editIndustryName = "";
    this.editTenantName = "";
  }

  openStatsPageByMarket(marketId: number) {
    window.open(
      `${environment.applicationURL}/tenant-stats/market/${marketId}/lang/en`,
      "_blank"
    );
  }

  translateLabel(name) {
    return this.i18.getTranslation(name);
  }

  setQuarter(date) {
    let [year, month, day] = date.split("-").map(Number);
    let input = new Date(year, month - 1, day);
    let quarter = Math.floor((input.getMonth() + 3) / 3);
    return `${quarter} ${
      this.browserLang === "pt" || this.browserLang === "es" ? "T" : "Q"
    } ${this.datePipe.transform(input, "yyyy")}`;
  }

  async validateSesion() {
    if (localStorage.getItem("token")) {
      return this.setUser();
    } else {
      let isValidToken = this.deviceRegistration.validateCookie();

      if (isValidToken) {
        let account = await this.accountService.getAccount();
        localStorage.setItem("user", JSON.stringify(account));
        return this.setUser();
      }
    }

    return false;
  }

  setUser() {
    let user = JSON.parse(localStorage.getItem("user"));
    this.isAdmin = user?.roles?.includes("ROLE_ADMIN");
    this.isResearchAdmin =
      user?.roles?.includes("ROLE_COUNTRY_MANAGER") ||
      user?.roles?.includes("ROLE_RESEARCH_LEAD");
    if (
      !user?.roles?.includes("ROLE_RESEARCH") &&
      !user?.roles?.includes("ROLE_COUNTRY_MANAGER") &&
      !user?.roles?.includes("ROLE_RESEARCH_LEAD")
    ) {
      this.snackBar.open("You don't have access to Research section", "Close", {
        duration: 3000, // Duration in milliseconds
        horizontalPosition: "center",
        verticalPosition: "top",
        panelClass: ["custom-snackbar"]
      });
      this.router.navigateByUrl("/");
      return false;
    }
    return true;
  }

  validateTrnaslate(text) {
    return this.siilaTranslationService.getTranslation(text);
  }

  isMX() {
    return this.platformId && this.platformId == "mx";
  }

  openChartPopup(content: any, mode: string) {
    if (mode == "edit") {
      setTimeout(() => {
        this.selectedTenant.checkboxEdit = true;
      }, 10);
    }

    if (mode == "moveOut") {
      setTimeout(() => {
        this.selectedTenant.checkboxMoveOut = true;
      }, 10);
    }

    if (mode == "moveIn") {
      setTimeout(() => {
        this.selectedTenant.checkboxMoveIn = true;
      }, 10);
    }

    this.modalService.open(content, {
      centered: true,
      backdrop: false,
      keyboard: false
    });
  }

  isFutureDate(date) {
    if (date) {
      const currentDate = new Date();
      const parsedDate = new Date(date + "T00:00:00");
      if (parsedDate > currentDate) {
        this.countPlannedDate++;
      }
      return parsedDate > currentDate ? this.formatDate(date) : "";
    }
    return "";
  }

  isPastDate(date) {
    if (date) {
      const currentDate = new Date();
      const parsedDate = new Date(date + "T00:00:00");
      return parsedDate > currentDate ? "" : this.formatDate(date);
    } else {
      return "";
    }
  }

  formatDate(value) {
    if (value != "") {
      const date = new Date(value + "T00:00:00");
      const month = date.toLocaleString("en", { month: "short" });
      const year = date.getFullYear();

      return this.browserLang === "es"
        ? `${monthNamesMap[month]} ${year}`
        : `${month} ${year}`;
    }
    return value;
  }

  translateStatus(status: string): string {
    if (status === "Active") {
      return "Activo";
    } else if (status === "Inactive") {
      return "Inactivo";
    }
  }

  onInputChange(event: Event): void {
    const input = (event.target as HTMLInputElement).value;
    this.searchSubject.next(input);
  }

  fetchAutocompleteResults(query: string): Observable<any[]> {
    const apiUrl = `https://your-api.com/search?query=${query}`;
    return this.http.get<any[]>(apiUrl);
  }

  onSelectOption(option: any): void {
    this.editTenantWithIndustry = option;
    this.editTenantName = option.tenantName;
    this.editIndustryName = option.industryName;
    this.autocompleteResults = []; // Clear the autocomplete dropdown
  }

  resetModalValues() {
    this.editTenantWithIndustry = null;
    this.editIndustryName = "";
    this.editTenantName = "";

    this.editMoveOutDate = null;
    this.editAskingRentMXN = null;
    this.editAskingRentUSD = null;

    this.autocompleteResults = [];
    this.isLoading = false;
    this.newTenantArea = null;
    this.splitSuite = false;

    this.selectedMarketSegment = null;
    this.showAddTenants = false;
    this.addTenantMode = false;
  }

  discardChanges() {
    if (this.currentAction == "edit") {
      this.selectedTenant.checkboxEdit = false;
      this.selectedTenant.action = null;
      this.resetModalValues();
      this.modalService.dismissAll();
    }

    if (this.currentAction == "moveOut") {
      this.selectedTenant.checkboxMoveOut = false;
      this.selectedTenant.action = null;
      this.resetModalValues();
      this.modalService.dismissAll();
    }
  }

  onCheckboxClick(event: Event, tenantRow: any, action): void {
    this.selectedTenant = tenantRow;
    this.currentAction = action;
    this.resetModalValues();
    if (action == "edit") {
      if (
        this.selectedTenant.action &&
        this.selectedTenant.action.actionType == 0
      ) {
        // Load this action
        this.editTenantName = this.selectedTenant.action.tenantNameEdit;
        if (this.selectedTenant.action.marketSegment) {
          this.editIndustryName = this.selectedTenant.action.marketSegment.name;
        }
      } else {
        //this.resetModalValues();
      }
    }

    if (action == "moveOut") {
      this.editTenantName = this.selectedTenant.tenantName;
      if (
        this.selectedTenant.action &&
        this.selectedTenant.action.actionType == 1
      ) {
        this.editMoveOutDate = this.selectedTenant.action.dateAction;
        this.editAskingRentMXN = this.selectedTenant.action.askingRentPerAreaMXN;
        this.editAskingRentUSD = this.selectedTenant.action.askingRentPerAreaUSD;
      } else {
        //this.resetModalValues();
      }
    }

    if (action == "moveIn") {
      if (
        this.selectedTenant.action &&
        this.selectedTenant.action.actionType == 2
      ) {
        this.editTenantName = this.selectedTenant.action.tenantNameEdit;
        this.editMoveOutDate = this.selectedTenant.action.dateAction;
        if (this.selectedTenant.action.marketSegment) {
          this.editIndustryName = this.selectedTenant.action.marketSegment.name;
        }
        this.splitSuite = this.selectedTenant.action.splitSuite;
        this.newTenantArea = this.selectedTenant.action.newTenantArea
          ? this.selectedTenant.action.newTenantArea
          : 0;
      }
    }

    this.openChartPopup(this.chartModal, action);
  }

  saveChanges() {
    if (this.currentAction == "edit" || this.currentAction == "moveIn") {
      if (
        !this.addTenantMode &&
        !this.editTenantWithIndustry &&
        this.selectedTenant.action &&
        this.selectedTenant.action.marketSegment
      ) {
        this.editTenantWithIndustry = {};
        this.editTenantWithIndustry.industryName = this.selectedTenant.action.marketSegment.name;
        this.editTenantWithIndustry.industryId = this.selectedTenant.action.marketSegment.id;
      } else {
        if (!this.editTenantWithIndustry && this.selectedMarketSegment) {
          this.editTenantWithIndustry = {};
          this.editTenantWithIndustry.industryName = this.selectedMarketSegment.name;
          this.editTenantWithIndustry.industryId = this.selectedMarketSegment.id;
        }
      }
    }

    if (this.currentAction == "edit") {
      this.selectedTenant.action = {
        actionType: 0,
        tenantNameEdit: this.editTenantName,
        marketSegment: {
          name: this.editTenantWithIndustry.industryName,
          id: this.editTenantWithIndustry.industryId
        },
        buildingTenant: {
          id: this.selectedTenant.id
        },
        dateAction: new Date().toISOString()
      };
    }

    if (this.currentAction == "moveOut") {
      this.selectedTenant.action = {
        actionType: 1,
        dateAction: this.editMoveOutDate,
        askingRentPerAreaMXN: this.editAskingRentMXN,
        askingRentPerAreaUSD: this.editAskingRentUSD,
        buildingTenant: {
          id: this.selectedTenant.id
        }
      };
    }

    if (this.currentAction == "moveIn") {
      this.selectedTenant.action = {
        actionType: 2,
        tenantNameEdit: this.editTenantName,
        marketSegment: {
          name: this.editTenantWithIndustry.industryName,
          id: this.editTenantWithIndustry.industryId
        },
        dateAction: this.editMoveOutDate,
        buildingTenant: {
          id: this.selectedTenant.id
        }
      };
      if (this.splitSuite) {
        this.selectedTenant.action.newTenantArea = this.newTenantArea;
        this.selectedTenant.action.splitSuite = true;
      }
    }

    this.modalService.dismissAll();
  }

  updateCheckboxValues(tenantsLastQuarter) {
    tenantsLastQuarter.forEach(tenant => {
      if (tenant.action && tenant.action.actionType == 0) {
        tenant.checkboxEdit = true;
      }
      if (tenant.action && tenant.action.actionType == 1) {
        tenant.checkboxMoveOut = true;
      }
      if (tenant.action && tenant.action.actionType == 2) {
        tenant.checkboxMoveIn = true;
      }
    });
  }

  closeModal() {
    this.snackBar.open("Closing modal!", "Close", {
      duration: 1000, // Duration in milliseconds
      horizontalPosition: "center",
      verticalPosition: "top",
      panelClass: ["custom-snackbar"]
    });
    if (!this.selectedTenant.action) {
      setTimeout(() => {
        this.selectedTenant.checkboxMoveIn = false;
        this.selectedTenant.checkboxEdit = false;
        this.selectedTenant.checkboxMoveOut = false;
      }, 10);
    }
    this.modalService.dismissAll();
  }

  getBuildingTenantChangesURL(buildingId) {
    return `${environment.applicationURL}/property/${buildingId}/tenants/lang/en`;
  }

  validateSaveButton() {
    if (this.currentAction == "edit") {
      let validation1 = this.editTenantName && this.editIndustryName;
      let validation2 =
        this.addTenantMode && this.selectedMarketSegment && this.editTenantName;
      return validation1 || validation2;
    }

    if (this.currentAction == "moveOut") {
      return this.editMoveOutDate;
    }

    if (this.currentAction == "moveIn") {
      let validation1 =
        this.editTenantName && this.editIndustryName && this.editMoveOutDate;
      let validation2 = true;
      if (
        this.splitSuite &&
        (this.newTenantArea > this.selectedTenant.area - 1 ||
          this.newTenantArea <= 0)
      ) {
        validation2 = false;
      }
      return validation1 && validation2;
    }
  }

  saveData() {}

  discardAllChanges() {
    this.snackBar.open(
      this.i18.get("tenants-property.changesDiscarded"),
      "Close",
      {
        horizontalPosition: "center",
        verticalPosition: "top",
        panelClass: ["custom-snackbar"]
      }
    );
    this.reloadTableData();
  }

  saveTenantsData(actions) {}

  getGLAFromTenants() {}

  getYearFromFirstTenant() {
    if (this.marketConfigs.length > 0) {
      return this.marketConfigs[0].year;
    }
    return "";
  }

  getQuarterFromFirstTenant() {
    if (this.marketConfigs.length > 0) {
      return this.marketConfigs[0].quarter;
    }
    return "";
  }

  getModalTitle() {
    if (this.currentAction == "edit") {
      return "Edit Tenant";
    }
    if (this.currentAction == "moveOut") {
      return "Move Out Tenant";
    }
    if (this.currentAction == "moveIn") {
      return "Move In Tenant";
    }
  }

  reloadTableData() {}

  onMarketChange(newSelection: any): void {
    this.researchData = null;
    this.propertyService
      .getResearchDataByMarket(this.selectedMarketConfig.marketId)
      .then((resp1: any) => {
        this.showDetail = true;
        this.researchData = resp1;
      });
  }

  onResearcherSelected(selectedName: string, marketId: number): void {
    const selectedResearcher = this.researchUsers.find(
      researcher => researcher.name === selectedName
    );

    // update building with new researcher
    this.propertyService
      .saveMarketResearcher(marketId, selectedResearcher.id)
      .then((resp: any) => {
        this.snackBar.open(
          this.i18.get("tenants-property.researcherUpdated"),
          "Close",
          {
            horizontalPosition: "center",
            verticalPosition: "top",
            panelClass: ["custom-snackbar"]
          }
        );
      });
  }

  onLeadSelected(selectedName: string, marketId: number): void {
    const selectedResearcher = this.leadResearchUsers.find(
      researcher => researcher.name === selectedName
    );

    // update building with new researcher
    this.propertyService
      .saveMarketLeadResearcher(marketId, selectedResearcher.id)
      .then((resp: any) => {
        this.snackBar.open(
          this.i18.get("tenants-property.leadResearcherUpdated"),
          "Close",
          {
            horizontalPosition: "center",
            verticalPosition: "top",
            panelClass: ["custom-snackbar"]
          }
        );
      });
  }

  getProgressBarLegend(data) {
    if (data.totalApproved == undefined) {
      return "0/0";
    }

    if (data.status == "APPROVED BY MGMT") {
      return "Completed";
    }

    let label = "" + data.totalApproved + "/" + data.totalBuildings;
    return label;
  }

  getRejectedLegend(data) {
    if (data.totalRejected > 0) {
      return "(" + data.totalRejected + ")";
    }
    return "";
  }

  downloadStatsCsvWithChanges() {
    this.showSpinner = true;
    this.propertyService
      .downloadAllStatsCsvWithChanges()
      .then(response => {
        const blob = new Blob([response], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);

        // Create a temporary link element to trigger the download
        const a = document.createElement("a");
        a.href = url;
        a.download = `stats_data.csv`;
        document.body.appendChild(a);
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch(error => {
        console.error("Error downloading CSV:", error);
        this.snackBar.open("Error downloading CSV file", "Close", {
          duration: 3000,
          horizontalPosition: "center",
          verticalPosition: "top",
          panelClass: ["custom-snackbar"]
        });
      })
      .finally(() => {
        this.showSpinner = false;
      });
  }
}
