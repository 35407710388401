<div class="content-detail">
  <div class="header-container">
    <h2>{{ "master-tenant.title" | translate }}</h2>
    <button mat-raised-button color="primary" (click)="openAddDialog()">
      {{ "master-tenant.addNewTenant" | translate }}
    </button>
  </div>
  <div class="table-content">
    <table class="table table-bordered text-center">
      <thead>
        <tr>
          <th>{{ "master-tenant.tenantName" | translate }}</th>
          <th>{{ "master-tenant.industry" | translate }}</th>
          <th>{{ "master-tenant.country" | translate }}</th>
          <th>{{ "master-tenant.contact" | translate }}</th>
          <th>{{ "master-tenant.tenantStatus" | translate }}</th>
          <th>{{ "master-tenant.dateCreated" | translate }}</th>
          <th>{{ "master-tenant.upadateCreated" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <input
              type="text"
              [(ngModel)]="filterName"
              (ngModelChange)="onInputChange()"
              [placeholder]="'master-tenant.filterByName' | translate"
              class="form-control margin-filter"
            />
          </td>
          <td>
            <select
              [(ngModel)]="filterIndustry"
              (change)="onInputChange()"
              class="form-select margin-filter"
            >
              <option [ngValue]="null">{{
                "master-tenant.industryOption" | translate
              }}</option>
              <option
                *ngFor="let industry of industries"
                [value]="industry.id"
                >{{ industry.name | langSelector }}</option
              >
            </select>
          </td>
          <td>
            <select
              [(ngModel)]="filterCountry"
              (change)="onInputChange()"
              class="form-select margin-filter"
            >
              <option [ngValue]="null">{{
                "master-tenant.industryOption" | translate
              }}</option>
              <option [ngValue]="''">{{
                "master-tenant.none" | translate
              }}</option>
              <option *ngFor="let country of countries" [value]="country">{{
                country
              }}</option>
            </select>
          </td>
          <td>
            <select
              [(ngModel)]="filterContact"
              (change)="onInputChange()"
              class="form-select margin-filter"
            >
              <option [ngValue]="null">{{
                "master-tenant.industryOption" | translate
              }}</option>
              <option [ngValue]="true"
                >{{ "master-tenant.yes" | translate }}
              </option>
              <option [ngValue]="false">{{
                "master-tenant.no" | translate
              }}</option>
            </select>
          </td>
          <td>
            <select
              [(ngModel)]="filterStatus"
              (change)="onInputChange()"
              class="form-select margin-filter"
            >
              <option [ngValue]="null">{{
                "master-tenant.industryOption" | translate
              }}</option>
              <option [ngValue]="true"
                >{{ "master-tenant.stats" | translate }}
              </option>
              <option [ngValue]="false">{{
                "master-tenant.noStats" | translate
              }}</option>
            </select>
          </td>
          <td>
            <input
              class="form-control margin-filter"
              type="number"
              [(ngModel)]="filterDate"
              (ngModelChange)="onInputChange()"
              [placeholder]="'master-tenant.filterByDate' | translate"
            />
          </td>
          <td></td>
        </tr>

        <tr *ngFor="let tenant of tenants">
          <td class="align-column">
            <mat-icon (click)="editTenant(tenant)">edit</mat-icon>
            <span>{{ tenant?.name }}</span>
          </td>
          <td>{{ tenant?.industry?.name | langSelector }}</td>
          <td>{{ tenant?.country }}</td>
          <td>
            {{
              validateContact(tenant)
                ? ("master-tenant.yes" | translate)
                : ("master-tenant.no" | translate)
            }}
          </td>
          <td>
            {{
              tenant?.includedInStats
                ? ("master-tenant.stats" | translate)
                : ("master-tenant.noStats" | translate)
            }}
          </td>
          <td>{{ tenant?.createdDate }}</td>
          <td>{{ tenant?.modifiedDate }}</td>
        </tr>
      </tbody>
    </table>
    <ngb-pagination
      [collectionSize]="total"
      [(page)]="page"
      [pageSize]="perPage"
      [maxSize]="5"
      (pageChange)="onPageChange($event)"
    ></ngb-pagination>
  </div>
</div>
