<div class="nav-container">
  <div class="col-md-12 color-span nav-detail">
    <div class="col-md-12 title-row f-left mt-1">
      <span class="title">
        {{ "tenants-property.tenantsInformationFor" | translate }}
        <span
          style="color: cornflowerblue; 
        text-decoration-line: underline; 
        cursor: pointer;"
          (click)="openPropertyPage()"
          >{{ property?.title | langSelector }}</span
        >
      </span>
    </div>
  </div>
</div>
<div class="content">
  <app-loading-spinner *ngIf="!showDetail"></app-loading-spinner>
  <div *ngIf="showDetail" class="content-detail">
    <div class="col-md-12 mt-4 ">
      <div class=" key-columns">
        <div
          class="col-md-6"
          [ngClass]="{ 'p-0': isMobile }"
          id="lastPeriodData"
        >
          <div class="col-md-12 key-facts mt-2" style="font-weight: 600;">
            {{ "tenants-property.lastPeriodData" | translate }}
          </div>
          <div class="d-flex key-facts-columns border-table">
            <div class="column label-color">
              {{ "tenants-property.year" | translate }}
            </div>
            <div class="column label-color2">
              {{ getYearFromConfig() }}
            </div>
            <div class="column label-color">
              {{ "tenants-property.period" | translate }}
            </div>
            <div class="column label-color2">
              {{ getQuarterFromConfig() }}
            </div>
            <div class="column label-color">
              {{ "tenants-property.property" | translate }}
            </div>
            <div class="column label-color2">
              {{ property?.title | langSelector }}
            </div>

            <div class="column label-color">
              {{ "tenants-property.gla" | translate }}
            </div>
            <div class="column label-color2">
              {{ getGLAFromTenants() | localNumber: "1.2-2" }}
            </div>

            <div class="column label-color">
              {{ "tenants-property.status" | translate }}
            </div>
            <div class="column label-color2">
              {{ getConfigStatusLabel() }}
            </div>

            <div class="column label-color">
              {{ "tenants-property.editFloorArea" | translate }}
            </div>
            <div class="column label-color2">
              <label
                style="color: #007bff; cursor: pointer; text-decoration: underline; text-transform: uppercase;"
                (click)="openFloorAreaModal()"
              >
                {{ "tenants-property.edit" | translate }}
              </label>
            </div>

            <div class="column label-color">
              {{ "tenants-property.askingRent" | translate }}
            </div>
            <div class="column label-color2">
              <label
                style="color: #007bff; cursor: pointer; text-decoration: underline; text-transform: uppercase;"
                (click)="openAskingRentModal()"
              >
                {{ "tenants-property.edit" | translate }}
              </label>
            </div>

            <div class="column label-color" *ngIf="isResearchAdmin">
              {{ "tenants-property.csvWithChanges" | translate }}
            </div>
            <div class="column label-color2" *ngIf="isResearchAdmin">
              <label
                style="color: #007bff; cursor: pointer; text-decoration: underline; text-transform: uppercase;"
                (click)="downloadStatsCsvWithChanges()"
              >
                {{ "tenants-property.download" | translate }}
              </label>
            </div>
          </div>
        </div>
        <div
          class="col-md-6"
          [ngClass]="{ 'p-0': isMobile }"
          id="actionButtons"
        >
          <div class="col-md-12 key-facts mt-2" style="font-weight: 600;">
            {{ "tenants-property.actions" | translate }}
          </div>
          <div
            class="key-facts-columns border-table actios-card"
            *ngIf="isResearcher"
          >
            <div
              class="column"
              style="text-align: center; display: flex;
            align-items: center;
            justify-content: center;"
              *ngIf="isConfigInitialStatus()"
            >
              <button
                class="btn btn-primary reload-data-button"
                (click)="discardAllChanges()"
                style="width: 280px; font-weight: 600;"
                [disabled]="!isConfigInitialStatus()"
              >
                {{ "tenants-property.reloadPriorQuarterData" | translate }}
              </button>
            </div>

            <div
              class="column label-color"
              style="text-align: center; display: flex;
            align-items: center;
            justify-content: center;"
              [matTooltip]="
                !isConfigInitialStatus()
                  ? i18.get('tenants-property.theseChangesAlreadySubmitted')
                  : null
              "
              matTooltipPosition="below"
            >
              <button
                class="btn btn-primary approve-button"
                (click)="openConfirmationDialog(1)"
                [disabled]="!isConfigInitialStatus() && validateSubmitAction()"
              >
                {{ getSubmitButtonLabel() }}
              </button>
            </div>

            <div
              class="column label-color"
              style="text-align: center; display: flex;
            align-items: center;
            justify-content: center;"
              [matTooltip]="
                !isConfigInitialStatus()
                  ? 'You cannot add changes, already submitted.'
                  : null
              "
              matTooltipPosition="below"
              *ngIf="!config.removedFromStats && isConfigInitialStatus()"
            >
              <button
                class="btn btn-primary reject-button"
                (click)="openConfirmationDialog(3)"
                style="width: 280px; font-weight: 600;"
                [disabled]="!isConfigInitialStatus()"
              >
                {{ "tenants-property.removeFromStats" | translate }}
              </button>
            </div>

            <div
              class="column label-color"
              style="text-align: center; display: flex;
            align-items: center;
            justify-content: center;"
            >
              <button
                class="btn btn-primary view-comments-button"
                (click)="printData()"
              >
                {{ "tenants-property.download" | translate }}
              </button>
            </div>

            <div
              class="column label-color"
              style="text-align: center; display: flex;
            align-items: center;
            justify-content: center;"
              [matTooltip]="
                !comments || comments.length == 0
                  ? 'No comments available.'
                  : null
              "
              matTooltipPosition="below"
            >
              <button
                class="btn btn-primary view-comments-button"
                (click)="openCommentsDialog()"
              >
                {{ "tenants-property.comments" | translate }}
              </button>
            </div>

            <div
              class="column label-color"
              style="text-align: center; display: flex;
            align-items: center;
            justify-content: center;"
              [matTooltip]="
                !notes || notes.length == 0
                  ? i18.get('tenants-property.noNotesAvailable')
                  : null
              "
              matTooltipPosition="below"
            >
              <button
                class="btn btn-primary view-comments-button"
                (click)="openNotesDialog()"
              >
                {{ "tenants-property.notes" | translate }}
              </button>
            </div>
          </div>

          <div
            class="key-facts-columns border-table"
            style="display: flex;
          align-content: center;
          justify-content: space-between;
          align-items: flex-end;
          flex-wrap: wrap;"
            *ngIf="isResearchAdmin"
          >
            <div
              class="column label-color"
              style="text-align: center; display: flex;
            align-items: center;
            justify-content: center;"
            >
              <button
                class="btn btn-primary reject-button"
                (click)="openRejectionDialog()"
                [disabled]="isConfigInitialStatus()"
              >
                {{ "tenants-property.reject" | translate }}
              </button>
            </div>

            <div
              class="column label-color"
              style="text-align: center; display: flex;
            align-items: center;
            justify-content: center;"
              [matTooltip]="getTooltipTextForApproveButton()"
              matTooltipPosition="below"
            >
              <button
                class="btn btn-primary approve-button"
                (click)="openConfirmationDialog(2)"
                [disabled]="isApproveButtonDisabled()"
              >
                {{ getApproveButtonLabel() }}
              </button>
            </div>

            <div
              class="column label-color"
              style="text-align: center; display: flex;
            align-items: center;
            justify-content: center;"
            >
              <button
                class="btn btn-primary view-comments-button"
                (click)="printData()"
              >
                {{ "tenants-property.download" | translate }}
              </button>
            </div>

            <div
              class="column label-color"
              style="text-align: center; display: flex;
            align-items: center;
            justify-content: center;"
            >
              <button
                class="btn btn-primary view-comments-button"
                (click)="openFlagsDialog()"
              >
                {{ "tenants-property.flags" | translate }}
              </button>
            </div>

            <div
              class="column label-color"
              style="text-align: center; display: flex;
            align-items: center;
            justify-content: center;"
              [matTooltip]="
                !comments || comments.length == 0
                  ? 'No comments available.'
                  : null
              "
              matTooltipPosition="below"
            >
              <button
                class="btn btn-primary view-comments-button"
                (click)="openCommentsDialog()"
              >
                {{ "tenants-property.comments" | translate }}
              </button>
            </div>

            <div
              class="column label-color"
              style="text-align: center; display: flex;
            align-items: center;
            justify-content: center;"
              [matTooltip]="
                !notes || notes.length == 0
                  ? i18.get('tenants-property.noNotesAvailable')
                  : null
              "
              matTooltipPosition="below"
            >
              <button
                class="btn btn-primary view-comments-button"
                (click)="openNotesDialog()"
              >
                {{ "tenants-property.notes" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 tenantheader">
        <div class="col-md-12 key-facts">
          <span style="font-weight: 600;"
            >{{ getYearFromFirstTenant() }} {{ getQuarterFromFirstTenant() }}
            {{ "tenants-property.tenants" | translate }}</span
          >
        </div>
        <div class="table-unitmix" #tableContainer>
          <table
            class="table"
            *ngIf="!config.removedFromStats"
            id="tenant-table"
          >
            <thead class="head-vacant title-row unitmixheaders">
              <tr>
                <th>{{ "tenants-property.moveIn" | translate }}</th>
                <th
                  *ngIf="property?.buildingType.id == 1001"
                  [innerHTML]="
                    getFloorModuleLabel('tenants-property.warehouse')
                  "
                >
                  {{ "tenants-property.warehouse" | translate }}
                </th>
                <th
                  *ngIf="property?.buildingType.id == 1001"
                  [innerHTML]="getFloorModuleLabel('tenants-property.module')"
                >
                  {{ "tenants-property.module" | translate }}
                </th>
                <th
                  *ngIf="property?.buildingType.id == 2001"
                  [innerHTML]="getFloorModuleLabel('tenants-property.floor')"
                >
                  {{ "tenants-property.floor" | translate }}
                </th>
                <th
                  *ngIf="property?.buildingType.id == 2001"
                  [innerHTML]="getFloorModuleLabel('tenants-property.suite')"
                >
                  {{ "tenants-property.suite" | translate }}
                </th>
                <th>
                  {{ "tenants-property.area" | translate }}
                </th>
                <!-- <th *ngIf="!isMX() && property?.buildingType.id == 2001">
                  {{ "tenants-property.bomaArea" | translate }}
                </th> -->
                <th>
                  {{ "tenants-property.tenant" | translate }}
                </th>
                <th>
                  {{ "tenants-property.reviewed" | translate }}
                </th>
                <th>
                  {{ "tenants-property.edit" | translate }}
                </th>
                <th>
                  {{ "tenants-property.moveOut" | translate }}
                </th>
              </tr>
            </thead>
            <tbody class="border-table title-row unitmixtablerows">
              <tr *ngFor="let tenantRow of tenantsLastQuarter; let i = index">
                <td>
                  <input
                    type="checkbox"
                    [ngClass]="{
                      'red-border-checkbox': shouldApplyRedBorder(tenantRow)
                    }"
                    [checked]="tenantRow.checkboxMoveIn"
                    [(ngModel)]="tenantRow.checkboxMoveIn"
                    [disabled]="
                      (tenantRow.tenantName ||
                        (tenantRow.tenantNameOption &&
                          tenantRow.tenantNameOption.id == 2)) &&
                      (!tenantRow.actionMoveOut ||
                        !tenantRow.actionMoveOut.moveInSameQuarter)
                    "
                    (click)="onCheckboxClick($event, tenantRow, 'moveIn')"
                  />
                </td>
                <td *ngIf="property?.buildingType.id == 1001">
                  {{ tenantRow.suiteWarehouse }}
                </td>
                <td *ngIf="property?.buildingType.id == 1001">
                  {{ tenantRow.floorModule }}
                </td>
                <td *ngIf="property?.buildingType.id == 2001">
                  {{ tenantRow.floorModule }}
                </td>
                <td *ngIf="property?.buildingType.id == 2001">
                  {{ tenantRow.suiteWarehouse }}
                </td>
                <td>
                  {{ tenantRow.area | localNumber: "1.2-2" }}
                </td>
                <!-- <td *ngIf="!isMX() && property?.buildingType.id == 2001">
                  {{ tenantRow.bomaArea | localNumber: "1.2-2" }}
                </td> -->
                <td style="text-align: left;">
                  <span *ngIf="tenantRow.tenantName">{{
                    tenantRow.tenantName
                  }}</span>
                  <span
                    *ngIf="
                      !tenantRow.tenantName &&
                      tenantRow.tenantNameOption.id == 1
                    "
                    >{{ "Vacant" }}</span
                  >
                  <span
                    *ngIf="
                      !tenantRow.tenantName &&
                      tenantRow.tenantNameOption.id == 2
                    "
                    >{{ "Unknown" }}</span
                  >
                  <span *ngIf="tenantRow.marketSegment">
                    ({{ tenantRow.marketSegment.name | langSelector }})</span
                  >
                </td>
                <td
                  [attr.data-label]="'tenants-property.reviewed' | translate"
                  style="text-align: center;"
                >
                  <input
                    type="checkbox"
                    [(ngModel)]="tenantRow.reviewed"
                    (change)="onReviewedChange($event, tenantRow)"
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    [disabled]="
                      ((tenantRow.tenantName ||
                        (tenantRow.tenantNameOption &&
                          tenantRow.tenantNameOption.id != 2)) &&
                        !isResearchAdmin) ||
                      (isResearchAdmin &&
                        tenantRow.tenantNameOption &&
                        tenantRow.tenantNameOption.id != 2)
                    "
                    [(ngModel)]="tenantRow.checkboxEdit"
                    [checked]="tenantRow.checkboxEdit"
                    (click)="onCheckboxClick($event, tenantRow, 'edit')"
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    [disabled]="
                      tenantRow.tenantNameOption &&
                      tenantRow.tenantNameOption.id == 1
                    "
                    [checked]="tenantRow.checkboxMoveOut"
                    [(ngModel)]="tenantRow.checkboxMoveOut"
                    (click)="onCheckboxClick($event, tenantRow, 'moveOut')"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div
            class="removed-from-stats"
            *ngIf="config.removedFromStats"
            style="text-align: center;"
          >
            <span>{{
              "tenants-property.markAsRemovedFromStats" | translate
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- NgbModal Template -->
  <ng-template #chartModal let-modal>
    <div class="custom-modal custom-modal-centered modal-80vh">
      <div class="modal-header">
        <h5 class="modal-title" style="font-weight: 600;">
          {{ getModalTitle() }}
        </h5>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          (click)="closeModal()"
        ></button>
      </div>
      <div class="modal-body mobile-table">
        <div class="table-tenant-popup">
          <div class="d-flex" *ngIf="currentAction == 'moveIn'">
            <div
              class="form-check form-switch"
              style="padding-left: 2.5em;"
              *ngIf="false"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault"
                [(ngModel)]="splitSuite"
                (click)="onSplitSuiteChange($event)"
              />
              <label class="form-check-label" for="flexSwitchCheckDefault">{{
                "tenants-property.splitSuite" | translate
              }}</label>
            </div>
          </div>
          <div
            class="d-flex align-items-center mb-3"
            *ngIf="currentAction == 'moveIn'"
          >
            <button
              class="btn btn-primary ml-2"
              (click)="addSplitRow()"
              [disabled]="!canAddMoreSplits()"
            >
              {{ "tenants-property.addSplitRow" | translate }}
            </button>
          </div>
          <table class="table" style="min-height: 150px;">
            <thead
              class="head-vacant"
              style="text-align: center; background-color: #5a86a3;"
            >
              <tr>
                <th
                  style="width: 80px;"
                  *ngIf="property?.buildingType.id == 2001"
                >
                  {{ "tenants-property.floor" | translate }}
                </th>
                <th
                  style="max-width: 100px;"
                  *ngIf="property?.buildingType.id == 2001"
                >
                  {{ "tenants-property.suite" | translate }}
                </th>
                <th
                  style="width: 80px;"
                  *ngIf="property?.buildingType.id == 1001"
                >
                  {{ "tenants-property.warehouse" | translate }}
                </th>
                <th
                  style="max-width: 100px;"
                  *ngIf="property?.buildingType.id == 1001"
                >
                  {{ "tenants-property.module" | translate }}
                </th>

                <th style="max-width: 100px;">
                  {{ "tenants-property.area" | translate }}
                </th>
                <!-- <th
                  style="min-width: 100px;"
                  *ngIf="!isMX() && property?.buildingType.id == 2001"
                >
                  {{ "tenants-property.bomaArea" | translate }}
                </th> -->
                <th style="min-width: 200px;">
                  {{ "tenants-property.tenant" | translate }}
                </th>
                <th
                  *ngIf="currentAction == 'edit' || currentAction == 'moveIn'"
                  style="max-width: 120px;"
                >
                  {{ "tenants-property.industry" | translate }}
                </th>
                <th
                  *ngIf="
                    currentAction == 'moveOut' || currentAction == 'moveIn'
                  "
                  style="max-width: 120px;"
                >
                  {{ "tenants-property.date" | translate }}
                </th>
                <th
                  *ngIf="currentAction == 'moveOut'"
                  style="max-width: 120px;"
                >
                  {{ "tenants-property.condition" | translate }}
                </th>
                <th
                  *ngIf="currentAction == 'moveOut'"
                  style="max-width: 120px;"
                >
                  <span *ngIf="isMX()">{{
                    "tenants-property.askingRentMXN" | translate
                  }}</span>
                  <span *ngIf="!isMX()">{{
                    "tenants-property.askingRent" | translate
                  }}</span>
                </th>
                <th
                  *ngIf="currentAction == 'moveOut' && isMX()"
                  style="max-width: 20px;"
                ></th>
                <th
                  *ngIf="currentAction == 'moveOut' && isMX()"
                  style="max-width: 120px;"
                >
                  {{ "tenants-property.askingRentUSD" | translate }}
                </th>

                <th
                  *ngIf="currentAction == 'moveOut'"
                  style="max-width: 120px;"
                >
                  {{ "tenants-property.moveInSameQuarter" | translate }}
                </th>
              </tr>
            </thead>
            <tbody style="text-align: center;">
              <tr style="height: 50px;">
                <td
                  *ngIf="property?.buildingType.id == 2001"
                  [attr.data-label]="'tenants-property.floorModule' | translate"
                >
                  {{ selectedTenant.floorModule }}
                </td>
                <td
                  *ngIf="property?.buildingType.id == 2001"
                  [attr.data-label]="
                    'tenants-property.suiteWarehouse' | translate
                  "
                >
                  {{ selectedTenant.suiteWarehouse }}
                </td>
                <td
                  *ngIf="property?.buildingType.id == 1001"
                  [attr.data-label]="'tenants-property.floorModule' | translate"
                >
                  {{ selectedTenant.suiteWarehouse }}
                </td>
                <td
                  *ngIf="property?.buildingType.id == 1001"
                  [attr.data-label]="
                    'tenants-property.suiteWarehouse' | translate
                  "
                >
                  {{ selectedTenant.floorModule }}
                </td>
                <td
                  [attr.data-label]="'tenants-property.area' | translate"
                  *ngIf="!splitSuite"
                  style="min-width: 120px;"
                >
                  <span>{{ getRemainingArea() | localNumber: "1.2-2" }}</span>
                  <mat-icon
                    class="info-icon"
                    style="font-size: 14px; height: 16px; width: 16px; margin-left: 4px; vertical-align: middle; color: #3106f3;"
                    [matTooltip]="getRemainingAreaTooltip()"
                    matTooltipPosition="below"
                    [matTooltipClass]="'tooltip-above-modal'"
                    *ngIf="selectedTenant.newArea > 0"
                    >info</mat-icon
                  >
                </td>
                <td
                  [attr.data-label]="'tenants-property.area' | translate"
                  *ngIf="splitSuite"
                >
                  <input
                    type="number"
                    [(ngModel)]="newTenantArea"
                    style="width: 100%;"
                    min="0"
                    max="{{ selectedTenant.area }}"
                  />
                </td>
                <!-- <td
                  [attr.data-label]="'tenants-property.bomaArea' | translate"
                  *ngIf="
                    !splitSuite && !isMX() && property?.buildingType.id == 2001
                  "
                >
                  {{ selectedTenant.bomaArea | localNumber: "1.2-2" }}
                </td> -->
                <!-- <td
                  [attr.data-label]="'tenants-property.bomaArea' | translate"
                  *ngIf="
                    splitSuite && !isMX() && property?.buildingType.id == 2001
                  "
                >
                  <input
                    type="number"
                    [(ngModel)]="newTenantAreaBoma"
                    style="width: 120px;"
                    min="0"
                    max="{{ selectedTenant.bomaArea }}"
                  />
                </td> -->
                <td [attr.data-label]="'tenants-property.tenant' | translate">
                  <div>
                    <div
                      style="position: relative; text-align: start;"
                      *ngIf="
                        currentAction != 'edit' && currentAction != 'moveIn'
                      "
                    >
                      <span> {{ editTenantName }} </span>
                    </div>
                    <div
                      style="position: relative; text-align: start; width: 100%;"
                      *ngIf="
                        currentAction == 'edit' || currentAction == 'moveIn'
                      "
                    >
                      <span *ngIf="addTenantMode"> {{ editTenantName }} </span>
                      <input
                        *ngIf="!addTenantMode"
                        type="text"
                        [(ngModel)]="editTenantName"
                        (input)="onInputChange($event)"
                        placeholder="Type to search..."
                        [ngClass]="{ loading: isLoading }"
                        style="width: 100%;"
                      />
                      <!-- Loading icon, only shown when isLoading is true -->
                      <span *ngIf="isLoading" class="loading-icon">🔄</span>

                      <!-- Autocomplete dropdown -->
                      <ul
                        *ngIf="autocompleteResults.length && !isLoading"
                        class="autocomplete-dropdown"
                        style="z-index: 1;"
                      >
                        <li
                          *ngFor="let option of autocompleteResults"
                          (click)="onSelectOption(option)"
                          [ngClass]="{
                            'blue-text': option.tenantName.includes(
                              'new tenant'
                            )
                          }"
                        >
                          {{ option.tenantName }}
                        </li>
                      </ul>
                    </div>
                    <div
                      style="text-align: left; font-size: smaller;"
                      *ngIf="showAddTenants && !addTenantMode"
                    >
                      <label
                        (click)="onAddTenant()"
                        style="cursor: pointer; color: #007bff; text-decoration: underline; margin: 0; padding: 0;"
                      >
                        {{ "tenants-property.addAsNewTenant" | translate }}
                      </label>
                    </div>
                    <div
                      style="text-align: left; font-size: smaller;"
                      *ngIf="addTenantMode"
                    >
                      <label
                        (click)="onBackToSearchTenant()"
                        style="cursor: pointer; color: #007bff; text-decoration: underline; margin: 0; padding: 0;"
                      >
                        {{ "tenants-property.backToSearch" | translate }}
                      </label>
                    </div>
                  </div>
                </td>
                <td
                  [attr.data-label]="'tenants-property.industry' | translate"
                  *ngIf="currentAction == 'edit' || currentAction == 'moveIn'"
                >
                  <select
                    [(ngModel)]="selectedMarketSegment"
                    style="width: 100%;"
                    *ngIf="true || addTenantMode"
                    [disabled]="
                      !isUnknownTenant(editTenantName) && !addTenantMode
                    "
                  >
                    <option
                      *ngFor="let segment of marketSegments"
                      [ngValue]="segment"
                    >
                      {{ segment.name | langSelector }}
                    </option>
                  </select>
                </td>
                <td
                  [attr.data-label]="'tenants-property.date' | translate"
                  *ngIf="
                    currentAction == 'moveOut' || currentAction == 'moveIn'
                  "
                >
                  <input
                    type="date"
                    [(ngModel)]="editMoveOutDate"
                    (ngModelChange)="onDateChange($event)"
                    (keydown)="disableManualEntry($event)"
                    style="width: 100%;"
                    [matTooltip]="getDateTooltip()"
                    matTooltipPosition="below"
                    [matTooltipClass]="'tooltip-above-modal'"
                  />
                </td>
                <td *ngIf="currentAction == 'moveOut'">
                  <select
                    name=""
                    id=""
                    [ngModel]="selectedTenant.actionMoveOut?.spaceCondition?.id"
                    (ngModelChange)="updateSpaceConditionId($event)"
                  >
                    <option
                      [ngValue]="condition.id"
                      *ngFor="let condition of conditions"
                    >
                      {{ condition.name | langSelector }}
                    </option>
                  </select>
                </td>
                <td
                  [attr.data-label]="
                    'tenants-property.askingRentMXN' | translate
                  "
                  *ngIf="currentAction == 'moveOut'"
                >
                  <input
                    type="number"
                    [(ngModel)]="editAskingRentMXN"
                    (input)="onInputChangeMXN($event)"
                    style="width: 120px;"
                    [ngStyle]="{
                      'background-color': usdOriginalCurrency
                        ? '#90979d'
                        : 'white'
                    }"
                    [matTooltip]="
                      !editMoveOutDate
                        ? 'Please enter a date for market rent currency exchange to process'
                        : null
                    "
                    matTooltipPosition="below"
                    [disabled]="disableCurrencyInput"
                    *ngIf="!disableCurrencyInput"
                  />
                </td>
                <td
                  data-label="or"
                  *ngIf="currentAction == 'moveOut' && isMX()"
                >
                  <span *ngIf="!disableCurrencyInput">or</span>
                </td>
                <td
                  [attr.data-label]="
                    'tenants-property.askingRentUSD' | translate
                  "
                  *ngIf="currentAction == 'moveOut' && isMX()"
                >
                  <input
                    type="number"
                    [(ngModel)]="editAskingRentUSD"
                    (input)="onInputChangeUSD($event)"
                    style="width: 120px;"
                    [ngStyle]="{
                      'background-color': !usdOriginalCurrency
                        ? '#90979d'
                        : 'white'
                    }"
                    [matTooltip]="
                      !editMoveOutDate
                        ? 'Please enter a date for market rent currency exchange to process'
                        : null
                    "
                    matTooltipPosition="below"
                    [disabled]="disableCurrencyInput"
                    *ngIf="!disableCurrencyInput"
                  />
                </td>
                <td
                  [attr.data-label]="
                    'tenants-property.moveInSameQuarter' | translate
                  "
                  *ngIf="currentAction == 'moveOut'"
                >
                  <input
                    type="checkbox"
                    [checked]="moveInSameQuarter"
                    [(ngModel)]="moveInSameQuarter"
                    (click)="onMoveInSameQuarterChange($event)"
                  />
                </td>
              </tr>
              <tr
                *ngFor="let split of additionalSplits; let i = index"
                style="height: 65px;"
              >
                <td *ngIf="property?.buildingType.id == 2001">
                  <span class="form-control-plaintext">
                    {{ split.floorModule }}
                  </span>
                </td>
                <td *ngIf="property?.buildingType.id == 2001">
                  <span class="form-control-plaintext">
                    {{ split.suiteWarehouse }}
                  </span>
                </td>
                <td *ngIf="property?.buildingType.id == 1001">
                  <span class="form-control-plaintext">
                    {{ split.suiteWarehouse }}
                  </span>
                </td>
                <td *ngIf="property?.buildingType.id == 1001">
                  <span class="form-control-plaintext">
                    {{ split.floorModule }}
                  </span>
                </td>
                <td>
                  <input
                    type="number"
                    [(ngModel)]="split.area"
                    (input)="onSplitAreaChange($event, i)"
                    class="form-control"
                    min="0"
                    [max]="selectedTenant.area"
                  />
                </td>
                <td>
                  <div *ngIf="!split.addTenantFlag">
                    <div style="position: relative; text-align: start;">
                      <span>Vacant</span>
                    </div>
                    <div style="text-align: left; font-size: smaller;">
                      <label
                        (click)="addTenantToAdditionalSplit(split)"
                        style="cursor: pointer; color: #007bff; text-decoration: underline; margin: 0; padding: 0;"
                      >
                        {{ "tenants-property.addTenant" | translate }}
                      </label>
                    </div>
                  </div>
                  <div *ngIf="split.addTenantFlag">
                    <div>
                      <div
                        style="position: relative; text-align: start; width: 100%;"
                      >
                        <span *ngIf="split.addTenantMode">
                          {{ split.tenantName }}
                        </span>
                        <input
                          *ngIf="!split.addTenantMode"
                          type="text"
                          [(ngModel)]="split.tenantName"
                          (input)="onInputChangeAdditionalSplit($event, split)"
                          placeholder="Type to search..."
                          [ngClass]="{ loading: split.isLoading }"
                          style="width: 100%;"
                        />
                        <span *ngIf="split.isLoading" class="loading-icon"
                          >🔄</span
                        >

                        <ul
                          *ngIf="
                            split.autocompleteResults.length && !split.isLoading
                          "
                          class="autocomplete-dropdown"
                          style="z-index: 1;"
                        >
                          <li
                            *ngFor="let option of split.autocompleteResults"
                            (click)="
                              onSelectOptionAdditionalSplit(option, split)
                            "
                            [ngClass]="{
                              'blue-text': option.tenantName.includes(
                                'new tenant'
                              )
                            }"
                          >
                            {{ option.tenantName }}
                          </li>
                        </ul>
                      </div>
                      <div
                        style="text-align: left; font-size: smaller;"
                        *ngIf="split.showAddTenants && !split.addTenantMode"
                      >
                        <label
                          (click)="onAddNewTenantToAdditionalSplit(split)"
                          style="cursor: pointer; color: #007bff; text-decoration: underline; margin: 0; padding: 0;"
                        >
                          {{ "tenants-property.addAsNewTenant" | translate }}
                        </label>
                      </div>
                      <div style="text-align: left; font-size: smaller;">
                        <label
                          (click)="onBackToVacantAdditionalSplit(split)"
                          style="cursor: pointer; color: #007bff; text-decoration: underline; margin: 0; padding: 0;"
                        >
                          {{ "tenants-property.backToVacant" | translate }}
                        </label>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <select
                    [(ngModel)]="split.marketSegment"
                    class="form-control"
                    [disabled]="
                      !isUnknownTenant(split.tenantName) && !split.addTenantMode
                    "
                  >
                    <option
                      *ngFor="let segment of marketSegments"
                      [ngValue]="segment"
                    >
                      {{ segment.name | langSelector }}
                    </option>
                  </select>
                </td>
                <td>
                  <input
                    type="date"
                    [(ngModel)]="split.dateAction"
                    class="form-control"
                  />
                </td>
                <td>
                  <button
                    class="btn btn-danger btn-sm"
                    (click)="removeSplitRow(i)"
                    style="padding: 0.25rem 0.5rem;"
                  >
                    <mat-icon
                      style="font-size: 18px; height: 18px; width: 18px; line-height: 18px;"
                      >delete</mat-icon
                    >
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="discardChanges()"
          *ngIf="
            (isConfigInitialStatus() && isResearcher) ||
            (isResearchAdmin &&
              (isConfigInitialStatus() || getConfigStatus() == 'SUBMITTED'))
          "
        >
          {{ "tenants-property.discardChanges" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          [disabled]="
            (!isConfigInitialStatus() && getConfigStatus() != 'SUBMITTED') ||
            !validateSaveButton()
          "
          *ngIf="
            (isConfigInitialStatus() && isResearcher) ||
            (isResearchAdmin &&
              getMarketConfigStatus() != 'MARKET SUBMITTED' &&
              getMarketConfigStatus() != 'APPROVED BY MGMT')
          "
          (click)="saveChanges()"
        >
          {{ "tenants-property.save" | translate }}
        </button>
        <button type="button" class="btn btn-secondary" (click)="closeModal()">
          {{ "tenants-property.close" | translate }}
        </button>
      </div>
    </div>
  </ng-template>
  <ng-template #confirmationDialog>
    <div
      class="modal-header"
      style="padding: 0; border-bottom: 0; padding-bottom: 30px;"
    >
      <h4 class="modal-title" style="font-weight: 600;">
        {{ "tenants-property.confirmation" | translate }}
      </h4>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="cancelSubmission()"
      ></button>
    </div>
    <div mat-dialog-content>
      <p>
        {{ getConfirmationDialogMessage() }}
      </p>
      <div *ngIf="confirmationDialogMode == 3">
        <div style="padding-bottom: 10px;">
          <textarea
            [(ngModel)]="newComment"
            style="width: 100%; height: 100px;"
          ></textarea>
        </div>
      </div>
    </div>
    <div class="modal-footer" style="padding: 0; padding-top: 10px; border: 0;">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="cancelSubmission()"
      >
        {{ "tenants-property.cancel" | translate }}
      </button>
      <button
        type="button"
        class="btn btn-primary"
        (click)="submitOrApprove()"
        [disabled]="isConfirmButtonDisabled()"
      >
        {{ "tenants-property.confirm" | translate }}
      </button>
    </div>
  </ng-template>
  <ng-template #commentsDialog>
    <div
      class="modal-header"
      style="padding: 0; border-bottom: 0; padding-bottom: 30px;"
    >
      <h4 class="modal-title" style="font-weight: 600;">
        {{ "tenants-property.comments" | translate }}
      </h4>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="cancelSubmission()"
      ></button>
    </div>
    <div mat-dialog-content style="width: 550px;">
      <div style="max-height: 300px;overflow: auto;">
        <div *ngFor="let comment of comments; let i = index">
          <div class="comment-row">
            {{ formatComment(comment) }}
          </div>
          <br />
        </div>
        <div
          *ngIf="!comments || comments.length == 0"
          style="font-style: italic;
    color: grey;"
        >
          <div class="comment-row">
            {{ "tenants-property.noCommentsAvailable" | translate }}
          </div>
          <br />
        </div>
      </div>

      <div
        style="text-align: left; font-size: smaller;"
        *ngIf="!showNewCommentBox"
        (click)="showNewComment()"
      >
        <label
          style="cursor: pointer; color: #007bff; text-decoration: underline; margin: 0; padding: 0;"
        >
          {{ "tenants-property.addNewComment" | translate }}
        </label>
      </div>
      <div *ngIf="showNewCommentBox">
        <div style="padding-bottom: 10px;">
          <textarea
            [(ngModel)]="newComment"
            style="width: 100%; height: 100px;"
          ></textarea>
        </div>
        <div>
          <button
            class="btn btn-primary"
            (click)="addNewComment()"
            [disabled]="!newComment || newComment.length < 10"
          >
            {{ "tenants-property.add" | translate }}
          </button>
        </div>
      </div>
    </div>
    <div class="modal-footer" style="padding: 0; padding-top: 10px; border: 0;">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="cancelSubmission()"
        *ngIf="isResearcher"
      >
        {{ "tenants-property.close" | translate }}
      </button>
      <button
        type="button"
        class="btn btn-secondary"
        (click)="cancelSubmission()"
        *ngIf="isResearchAdmin"
      >
        {{ "tenants-property.cancel" | translate }}
      </button>
      <button type="button" class="btn btn-primary" (click)="saveComments()">
        {{ "tenants-property.saveComments" | translate }}
      </button>
      <button
        type="button"
        class="btn btn-primary reject-button2"
        (click)="rejectAndSaveComments()"
        *ngIf="isResearchAdmin && rejectionMode"
        [disabled]="!comments || comments.length == 0 || disableRejectButton()"
      >
        {{ "tenants-property.confirmRejection" | translate }}
      </button>
    </div>
  </ng-template>
  <ng-template #notesDialog>
    <div
      class="modal-header"
      style="padding: 0; border-bottom: 0; padding-bottom: 30px;"
    >
      <h4 class="modal-title" style="font-weight: 600;">
        {{ "tenants-property.notes" | translate }}
      </h4>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="cancelSubmission()"
      ></button>
    </div>
    <div mat-dialog-content style="width: 550px;">
      <div style="max-height: 300px;overflow: auto;">
        <div *ngFor="let note of notes; let i = index">
          <div class="comment-row">
            {{ formatNote(note) }}
          </div>
          <br />
        </div>
        <div
          *ngIf="!notes || notes.length == 0"
          style="font-style: italic;
    color: grey;"
        >
          <div class="comment-row">
            {{ "tenants-property.noNotesYet" | translate }}
          </div>
          <br />
        </div>
      </div>

      <div
        style="text-align: left; font-size: smaller;"
        *ngIf="!showNewNoteBox"
        (click)="showNewNote()"
      >
        <label
          style="cursor: pointer; color: #007bff; text-decoration: underline; margin: 0; padding: 0;"
        >
          {{ "tenants-property.addNewNote" | translate }}
        </label>
      </div>
      <div *ngIf="showNewNoteBox">
        <div style="padding-bottom: 10px;">
          <textarea
            [(ngModel)]="newNote"
            style="width: 100%; height: 100px;"
          ></textarea>
        </div>
        <div>
          <button
            class="btn btn-primary"
            (click)="addNewNote()"
            [disabled]="!newNote || newNote.length < 10"
          >
            {{ "tenants-property.add" | translate }}
          </button>
        </div>
      </div>
    </div>
    <div class="modal-footer" style="padding: 0; padding-top: 10px; border: 0;">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="cancelSubmission()"
      >
        {{ "tenants-property.cancel" | translate }}
      </button>
      <button type="button" class="btn btn-primary" (click)="saveNotes()">
        {{ "tenants-property.saveNotes" | translate }}
      </button>
    </div>
  </ng-template>
  <ng-template #floorAreaModal>
    <div
      class="modal-header custom-modal-class-tenants"
      style="padding: 0; border-bottom: 0; padding-bottom: 30px;"
    >
      <h4 class="modal-title" style="font-weight: 600;">
        {{ "tenants-property.floorSuiteArea" | translate }}
      </h4>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="cancelSubmission()"
      ></button>
    </div>
    <div mat-dialog-content>
      <!-- Area Type Selector -->
      <div class="area-type-selector" style="margin-bottom: 20px;">
        <div class="btn-group" role="group">
          <button
            type="button"
            class="btn"
            [class.btn-primary]="selectedAreaType === 'floor'"
            [class.btn-secondary]="selectedAreaType !== 'floor'"
            (click)="switchAreaType('floor')"
          >
            <span *ngIf="property?.buildingType.id == 2001">
              {{ "tenants-property.floorArea" | translate }}
            </span>
            <span *ngIf="property?.buildingType.id == 1001">
              {{ "tenants-property.warehouseArea" | translate }}
            </span>
          </button>
          <button
            type="button"
            class="btn"
            [class.btn-primary]="selectedAreaType === 'suite'"
            [class.btn-secondary]="selectedAreaType !== 'suite'"
            (click)="switchAreaType('suite')"
          >
            <span *ngIf="property?.buildingType.id == 2001">
              {{ "tenants-property.suiteArea" | translate }}
            </span>
            <span *ngIf="property?.buildingType.id == 1001">
              {{ "tenants-property.moduleArea" | translate }}
            </span>
          </button>
        </div>
      </div>

      <!-- Pause Calculations Checkbox - Only shown for Suite Area -->
      <div
        *ngIf="selectedAreaType === 'suite'"
        class="pause-calculations-wrapper"
        style="margin-bottom: 15px;"
      >
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            id="pauseCalculations"
            [(ngModel)]="pauseCalculations"
          />
          <label
            class="form-check-label"
            for="pauseCalculations"
            style="color: #666;"
          >
            Pause Calculations
          </label>
        </div>
      </div>

      <div class="modal-table-container">
        <!-- Floor Area Table -->
        <div *ngIf="selectedAreaType === 'floor'">
          <table class="table mobile-table">
            <thead>
              <tr>
                <th *ngIf="property?.buildingType.id == 2001">
                  {{ "tenants-property.floor" | translate }}
                </th>
                <th *ngIf="property?.buildingType.id == 1001">
                  {{ "tenants-property.warehouse" | translate }}
                </th>
                <th>{{ "tenants-property.currentArea" | translate }}</th>
                <th>{{ "tenants-property.newArea" | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let area of floorAreas">
                <td [attr.data-label]="'tenants-property.floor' | translate">
                  {{ area.floor }}
                </td>
                <td
                  [attr.data-label]="'tenants-property.currentArea' | translate"
                >
                  {{ area.currentArea | localNumber: "1.0-2" }}
                </td>
                <td [attr.data-label]="'tenants-property.newArea' | translate">
                  <input
                    type="number"
                    class="form-control"
                    [(ngModel)]="area.newArea"
                    style="width: 100%; margin: 0 auto;"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Suite Area Table -->
        <div *ngIf="selectedAreaType === 'suite'">
          <table class="table mobile-table">
            <thead>
              <tr>
                <th *ngIf="property?.buildingType.id == 2001">
                  {{ "tenants-property.floor" | translate }}
                </th>
                <th *ngIf="property?.buildingType.id == 1001">
                  {{ "tenants-property.warehouse" | translate }}
                </th>
                <th>{{ "tenants-property.suiteModule" | translate }}</th>
                <th>{{ "tenants-property.tenant" | translate }}</th>
                <th>{{ "tenants-property.floorArea" | translate }}</th>
                <th>{{ "tenants-property.currentArea" | translate }}</th>
                <th>{{ "tenants-property.newArea" | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let suite of suiteAreas">
                <td *ngIf="property?.buildingType.id == 2001">
                  {{ suite.floorModule }}
                </td>
                <td *ngIf="property?.buildingType.id == 2001">
                  {{ suite.suiteWarehouse }}
                </td>
                <td *ngIf="property?.buildingType.id == 1001">
                  {{ suite.suiteWarehouse }}
                </td>
                <td *ngIf="property?.buildingType.id == 1001">
                  {{ suite.floorModule }}
                </td>
                <td>
                  <span *ngIf="suite.tenantName">{{ suite.tenantName }}</span>
                  <span
                    *ngIf="!suite.tenantName && suite.tenantNameOption.id == 1"
                    >{{ "Vacant" }}</span
                  >
                  <span
                    *ngIf="!suite.tenantName && suite.tenantNameOption.id == 2"
                    >{{ "Unknown" }}</span
                  >
                </td>
                <td>
                  {{ suite.newFloorArea ?? suite.floorArea | localNumber: "1.0-2" }}
                </td>
                <td>{{ suite.area | localNumber: "1.0-2" }}</td>
                <td>
                  <input
                    type="number"
                    class="form-control"
                    [(ngModel)]="suite.newArea"
                    (input)="onInputChangeSuiteArea($event, suite)"
                    style="width: 100%; margin: 0 auto;"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="modal-footer" style="padding: 0; padding-top: 10px; border: 0;">
      <button
        type="button"
        class="btn btn-secondary"
        *ngIf="selectedAreaType === 'suite'"
        (click)="discardSuiteAreaChanges()"
      >
        {{ "tenants-property.discardChanges" | translate }}
      </button>
      <button
        type="button"
        class="btn btn-secondary"
        (click)="cancelSubmission()"
      >
        {{ "tenants-property.close" | translate }}
      </button>
      <button
        type="button"
        class="btn btn-primary"
        (click)="saveFloorOrSuiteAreas()"
      >
        {{ "tenants-property.save" | translate }}
      </button>
    </div>
  </ng-template>
  <ng-template #askingRentModal>
    <div
      class="modal-header custom-modal-class-tenants"
      style="padding: 0; border-bottom: 0; padding-bottom: 30px;"
    >
      <h4 class="modal-title" style="font-weight: 600;">
        {{ "tenants-property.askingRentFee" | translate }}
      </h4>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="cancelSubmission()"
      ></button>
    </div>
    <div mat-dialog-content>
      <div
        class="period-info"
        style="display: flex; gap: 20px; margin-bottom: 20px;"
      >
        <div>
          <label>{{ "tenants-property.year" | translate }}:</label>
          <span style="margin-left: 8px;">{{ getYearFromFirstTenant() }}</span>
        </div>
        <div>
          <label>{{ "tenants-property.period" | translate }}:</label>
          <span style="margin-left: 8px;">{{
            getQuarterFromFirstTenant()
          }}</span>
        </div>
        <div>
          <label *ngIf="isMX()"
            >{{ "tenants-property.maintenanceFee" | translate }}:</label
          >
          <label *ngIf="!isMX()"
            >{{ "tenants-property.monthlyManagementFees" | translate }}:</label
          >
          <input
            type="number"
            class="form-control"
            [(ngModel)]="askingRentData.maintenanceFee"
            style="width: 120px; display: inline-block; margin-left: 8px;"
          />
        </div>
        <div *ngIf="!isMX()">
          <label
            >{{ "tenants-property.monthlyPropertyTax" | translate }}:</label
          >
          <input
            type="number"
            class="form-control"
            [(ngModel)]="askingRentData.monthlyPropertyTax"
            style="width: 120px; display: inline-block; margin-left: 8px;"
          />
        </div>
      </div>

      <div style="max-height: 400px; overflow: auto;">
        <table class="table mobile-table" id="askingRentTable">
          <thead>
            <tr>
              <th>{{ "tenants-property.floorModule" | translate }}</th>
              <th>{{ "tenants-property.suiteWarehouse" | translate }}</th>
              <th>{{ "tenants-property.tenant" | translate }}</th>
              <th>
                <span *ngIf="isMX()">{{
                  "tenants-property.askingRentMXN" | translate
                }}</span>
                <span *ngIf="!isMX()">{{
                  "tenants-property.askingRentReales" | translate
                }}</span>
              </th>
              <th *ngIf="isMX()">
                {{ "tenants-property.askingRentUSD" | translate }}
              </th>
              <th>{{ "tenants-property.condition" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let row of askingRentRows"
              [ngClass]="{ 'moved-out': row.moveOut }"
            >
              <td>{{ row.floorModule }}</td>
              <td>{{ row.suiteWarehouse }}</td>
              <td>
                {{
                  !row.tenantName && row.moveOut
                    ? "Unknown"
                    : row.tenantName
                    ? row.tenantName
                    : "Vacant"
                }}
              </td>
              <td>
                <input
                  type="number"
                  class="form-control"
                  [(ngModel)]="row.askingRentPerArea"
                  (input)="onInputChangeAskingRentPerArea($event, row)"
                />
              </td>
              <td *ngIf="isMX()">
                <input
                  type="number"
                  class="form-control"
                  [(ngModel)]="row.askingRentPerAreaUSD"
                  (input)="onInputChangeAskingRentPerAreaUSD($event, row)"
                />
              </td>
              <td>
                <select class="form-control" [(ngModel)]="row.conditionId">
                  <option [value]="null"></option>
                  <option
                    *ngFor="let condition of conditions"
                    [value]="condition.id"
                  >
                    {{ condition.name | langSelector }}
                  </option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="modal-footer" style="padding: 0; padding-top: 10px; border: 0;">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="discardAskingRent()"
      >
        {{ "tenants-property.discardChanges" | translate }}
      </button>
      <button
        type="button"
        class="btn btn-secondary"
        (click)="cancelSubmission()"
      >
        {{ "tenants-property.close" | translate }}
      </button>
      <button type="button" class="btn btn-primary" (click)="saveAskingRent()">
        {{ "tenants-property.save" | translate }}
      </button>
    </div>
  </ng-template>
  <ng-template #unsavedChangesModal id="unsaved-changes-modal">
    <div class="modal-header custom-modal-class-tenants">
      <h4 class="modal-title" style="font-weight: 600;">
        {{ "tenants-property.confirmation" | translate }}
      </h4>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="cancelSwitch()"
      ></button>
    </div>
    <div class="modal-body">
      <p>{{ "tenants-property.changesInFloorAreas" | translate }}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="cancelSwitch()">
        {{ "tenants-property.cancel" | translate }}
      </button>
      <button type="button" class="btn btn-primary" (click)="confirmSwitch()">
        {{ "tenants-property.save" | translate }}
      </button>
    </div>
  </ng-template>
  <ng-template #flagsDialog>
    <div
      class="modal-header"
      style="padding: 0; border-bottom: 0; padding-bottom: 15px;"
    >
      <h4 class="modal-title" style="font-weight: 600;">
        {{ "tenants-property.flags" | translate }}
      </h4>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="cancelSubmission()"
      ></button>
    </div>
    <div mat-dialog-content style="width: 550px;">
      <div style="max-height: 350px;overflow: auto;">
        <!-- Automatic Flags Section -->
        <div class="automatic-flags-section" *ngIf="hasAutomaticFlags()">
          <h5>{{ "tenants-property.automaticFlags.title" | translate }}</h5>
          <div
            class="automatic-flag-item"
            *ngIf="automaticFlags.hasFloorExpansion"
          >
            {{
              "tenants-property.automaticFlags.hasFloorExpansion" | translate
            }}
          </div>
          <div class="automatic-flag-item" *ngIf="automaticFlags.hasSplitSuite">
            {{ "tenants-property.automaticFlags.hasSplitSuite" | translate }}
          </div>
          <div
            class="automatic-flag-item"
            *ngIf="automaticFlags.hasSuiteAreaChange"
          >
            {{
              "tenants-property.automaticFlags.hasSuiteAreaChange" | translate
            }}
          </div>
          <div
            class="automatic-flag-item"
            *ngIf="automaticFlags.removedFromStats"
          >
            {{ "tenants-property.automaticFlags.removedFromStats" | translate }}
          </div>
          <div
            class="automatic-flag-item"
            *ngIf="automaticFlags.firstTimeInStats"
          >
            {{ "tenants-property.automaticFlags.firstTimeInStats" | translate }}
          </div>
          <div
            class="automatic-flag-item"
            *ngIf="automaticFlags.hasActionOutsideCurrentQuarter"
          >
            {{
              "tenants-property.automaticFlags.hasActionsOutsideCurrentQuarter"
                | translate
            }}
          </div>
        </div>

        <!-- Manual Flags Section -->
        <div class="manual-flags-section">
          <h5>{{ "tenants-property.manualFlags.title" | translate }}</h5>
          <div *ngIf="flags && flags.length > 0">
            <div *ngFor="let flag of flags" class="flag-row">
              {{ formatFlag(flag) }}
            </div>
          </div>
          <div
            *ngIf="!flags || flags.length == 0"
            class="flag-row"
            style="font-style: italic;"
          >
            {{ "tenants-property.noFlagsAvailable" | translate }}
          </div>
        </div>

        <div
          style="text-align: left; font-size: smaller; margin-top: 16px;"
          *ngIf="!showNewFlagBox"
          (click)="showNewFlag()"
        >
          <label
            style="cursor: pointer; color: #007bff; text-decoration: underline; margin: 0; padding: 0;"
          >
            {{ "tenants-property.addNewFlag" | translate }}
          </label>
        </div>
        <div *ngIf="showNewFlagBox">
          <div style="padding-bottom: 10px;">
            <textarea
              [(ngModel)]="newFlag"
              style="width: 100%; height: 100px;"
            ></textarea>
          </div>
          <div>
            <button
              class="btn btn-primary"
              (click)="addNewFlag()"
              [disabled]="!newFlag || newFlag.length < 10"
            >
              {{ "tenants-property.add" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer" style="padding: 0; padding-top: 10px; border: 0;">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="cancelSubmission()"
      >
        {{ "tenants-property.close" | translate }}
      </button>
      <button type="button" class="btn btn-primary" (click)="saveFlags()">
        {{ "tenants-property.saveFlags" | translate }}
      </button>
    </div>
  </ng-template>
  <wait-spinner *ngIf="showSpinner"></wait-spinner>
</div>
