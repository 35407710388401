import { Component } from "@angular/core";

@Component({
  selector: "wait-spinner",
  templateUrl: "./wait-spinner.component.html",
  styleUrls: ["./wait-spinner.component.scss"]
})
export class WaitSpinnerComponent {
  constructor() {}
}
