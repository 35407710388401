export class Industry {
  id: number;
  name: string;
  createdDate: string;
  modifiedDate: string | null;
  category?: IndustryCategory;
}

export class IndustryCategory {
  id: number;
  name: string;
  createdDate: string;
  modifiedDate: string | null;
}
export class Tenant {
  id: number;
  name: string;
  industry: Industry;
  country: string;
  contactUrl: string;
  contactPhone: string;
  contactName: string;
  contactEmail?: string | null;
  createdDate: string;
  modifiedDate?: string | null;
  developer: boolean;
  owner: boolean;
  tenant: boolean;
}
